import { SFPositionSchema } from '../base';
import { z } from 'zod';

export const SFPositionUpsertSchema = SFPositionSchema.pick({
  salesforce_id: true,
  dt_end: true,
  dt_start: true,
  duration: true,
  monthly_rate: true,
  name: true,

  quantity: true,
  project_id: true,
}).extend({
  notes: z.string().nullish(),
});

export type SFPositionUpsert = z.infer<typeof SFPositionUpsertSchema>;
